<div class="relative">
  <div
    class="flex h-[40px] items-center w-[800px] max-w-full rounded-full border border-gray-300 dark:border-gray-600 bg-transparent focus-within:border-blue-500 focus-within:ring focus-within:ring-blue-300 transition"
  >
    <input
      #searchInput
      type="text"
      class="flex-1 bg-transparent placeholder-gray-600 dark:placeholder-gray-400 text-gray-900 dark:text-gray-200 text-center focus:outline-none focus:ring-0"
      [placeholder]="searchInput === document.activeElement ? '' : 'Search for Shipments, Facilities, and Trailers'"
      autocomplete="off"
      [formControl]="searchBox"
      (focus)="openDropdown()"
      (blur)="searchInput.blur()"
      (keydown)="handleKeydown($event)"
    />

    <span
      class="material-icons px-3 py-0.5 cursor-pointer select-none bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent outline-none focus:outline-none hover:outline-none"
      [ngClass]="
        filterStartDate.value || filterEndDate.value
          ? 'text-blue-500 dark:text-blue-400'
          : 'text-gray-600 dark:text-gray-400'
      "
      (click)="toggleDateFilter($event)"
      [matTooltip]="filterStartDate.value || filterEndDate.value ? 'Date filter active' : 'Filter by date'"
      >event</span
    >

    <mat-progress-spinner
      *ngIf="active$ | async"
      mode="indeterminate"
      diameter="20"
      class="pr-3"
    ></mat-progress-spinner>
  </div>

  <div *ngIf="dropdownOpen">
    <ng-container *ngIf="{ results: maxLoadSearchResults$ | async } as searchResults">
      <div
        *ngIf="searchResults.results?.results?.length"
        class="absolute w-[800px] bg-white/75 dark:bg-zinc-900/75 shadow-lg border border-gray-200 dark:border-zinc-700 z-50 max-h-96 overflow-y-auto mt-1 rounded-lg backdrop-blur-sm"
        (click)="$event.stopPropagation()"
      >
        <a
          *ngFor="
            let res of searchResults.results?.results;
            trackBy: searchResults.results?.results | trackByKey : 'id'
          "
          class="flex flex-col cursor-pointer p-3 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition rounded-md"
          (click)="closeDropdown()"
          [routerLink]="getRouterLink(res)"
        >
          <div class="flex mb-2">
            <span
              *ngIf="res.type === 'Shipment'; else nonShip"
              class="text-left font-semibold text-gray-800 dark:text-gray-200 text-base"
            >
              <div *ngIf="res.searchResultStatus === loadStatuses.completed">{{ res.searchResult }} - Completed</div>
              <div *ngIf="res.searchResultStatus === loadStatuses.cancelled">{{ res.searchResult }} - Cancelled</div>
              <div *ngIf="res.searchResultStatus === loadStatuses.inProgress">{{ res.searchResult }} - In Progress</div>
              <div
                *ngIf="
                  res.searchResultStatus !== loadStatuses.inProgress &&
                  res.searchResultStatus !== loadStatuses.completed &&
                  res.searchResultStatus !== loadStatuses.cancelled
                "
              >
                {{ res.searchResult }} - Scheduled
              </div>
            </span>
            <ng-template #nonShip class="text-left font-semibold text-gray-800 dark:text-gray-200 text-base">
              {{ res.searchResult }} {{ res.searchResultStatus ? ' - ' + res.searchResultStatus : '' }}
            </ng-template>
            <span class="text-gray-600 dark:text-gray-400 text-sm flex-1 text-right">
              {{ res.type }}
            </span>
          </div>
          <div class="flex flex-row justify-between items-center">
            <div class="text-gray-600 dark:text-gray-400 text-sm flex-1 text-left">
              {{ res.description ? res.description : '' }}
            </div>
            <div class="text-gray-600 dark:text-gray-400 text-sm flex-1 text-right">
              {{ res.timeWindow ? res.timeWindow : '' }}
            </div>
          </div>
        </a>

        <div *ngIf="searchResults.results?.showMore" class="p-2 text-center">
          <button color="accent" mat-button (click)="showMoreLoads()">Show More</button>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="dateFilterOpen"
    class="absolute right-0 top-12 bg-white/75 dark:bg-zinc-900/75 shadow-lg border border-gray-200 dark:border-zinc-700 rounded-lg p-4 z-50 backdrop-blur-sm w-[300px]"
    (click)="$event.stopPropagation()"
  >
    <div class="text-lg font-semibold mb-4 text-gray-800 dark:text-gray-200">Filter by Date</div>

    <div class="mb-4">
      <mat-form-field class="w-full bg-transparent" appearance="outline">
        <mat-label>Start Date</mat-label>
        <div class="flex">
          <input
            matInput
            [matDatepicker]="startPicker"
            [formControl]="filterStartDate"
            (click)="$event.stopPropagation()"
            class="bg-transparent"
          />
          <div class="flex items-center">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <button
              *ngIf="filterStartDate.value"
              mat-icon-button
              class="ml-[-8px] bg-transparent hover:bg-transparent focus:bg-transparent"
              (click)="$event.stopPropagation(); filterStartDate.setValue(null)"
            >
              <mat-icon class="text-[18px]">close</mat-icon>
            </button>
          </div>
        </div>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="mb-2">
      <mat-form-field class="w-full bg-transparent" appearance="outline">
        <mat-label>End Date</mat-label>
        <div class="flex">
          <input
            matInput
            [matDatepicker]="endPicker"
            [formControl]="filterEndDate"
            (click)="$event.stopPropagation()"
            class="bg-transparent"
          />
          <div class="flex items-center">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <button
              *ngIf="filterEndDate.value"
              mat-icon-button
              class="ml-[-8px] bg-transparent hover:bg-transparent focus:bg-transparent"
              (click)="$event.stopPropagation(); filterEndDate.setValue(null)"
            >
              <mat-icon class="text-[18px]">close</mat-icon>
            </button>
          </div>
        </div>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
