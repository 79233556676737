import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  debounceTime,
  Observable,
  of,
  Subject,
  switchMap,
  Subscription,
} from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { LohiLoadStatus, VoogleSearchResult } from '../types';
import { shareReplayComponentConfig } from '../constants';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'tb-voogle',
  templateUrl: './voogle-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoogleSearchComponent implements OnInit, OnDestroy {
  @ViewChild('startPicker') protected startPicker: MatDatepicker<Date>;
  @ViewChild('endPicker') protected endPicker: MatDatepicker<Date>;

  public searchBox = new FormControl<string>(null);
  public filterStartDate = new FormControl<Date>(null);
  public filterEndDate = new FormControl<Date>(null);

  private loading$$ = new BehaviorSubject<boolean>(false);
  private searchText$$ = new Subject<string>();
  public searchResults$: Observable<VoogleSearchResult[]>;
  private searchResults$$ = new BehaviorSubject<VoogleSearchResult[]>([]);
  public dropdownOpen = false;
  public dateFilterOpen = false;

  private subscriptions: Subscription[] = [];

  private maxLoadsToShow$$ = new BehaviorSubject<number>(10);
  public maxLoadsToShow$ = this.maxLoadsToShow$$.pipe(shareReplay(shareReplayComponentConfig));
  public maxLoadSearchResults$: Observable<{
    results: VoogleSearchResult[];
    showMore: boolean;
  }>;
  public active$: Observable<boolean>;

  constructor(private http: HttpClient) {
    // The searchResults$ is now tied directly to the search results observable from the API response
    this.searchResults$ = this.searchResults$$.asObservable();
    this.active$ = this.loading$$.asObservable();

    this.maxLoadSearchResults$ = this.searchResults$.pipe(
      combineLatestWith(this.maxLoadsToShow$),
      map(([results, max]) => {
        return {
          results: results.slice(0, max),
          showMore: results.length > max,
        };
      }),
    );

    this.subscriptions.push(this.searchBox.valueChanges.subscribe((value) => this.search(value)));
    this.listenToSearchTextChange();
  }

  public ngOnInit(): void {
    // Subscribe to date filter changes and trigger search
    this.subscriptions.push(
      this.filterStartDate.valueChanges.subscribe(() => {
        if (this.searchBox.value) {
          this.search(this.searchBox.value);
        }
      }),
    );

    this.subscriptions.push(
      this.filterEndDate.valueChanges.subscribe(() => {
        if (this.searchBox.value) {
          this.search(this.searchBox.value);
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    // Clean up subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public showMoreLoads() {
    this.maxLoadsToShow$$.next(this.maxLoadsToShow$$.value + 5);
  }

  public openDropdown() {
    this.dropdownOpen = true;
  }

  public closeDropdown() {
    this.dropdownOpen = false;
  }

  /**
   * Toggle the date filter dialog
   */
  public toggleDateFilter(event: MouseEvent) {
    if (!this.dropdownOpen) {
      //If we are date filtering, the search results should be open :orc:
      this.dropdownOpen = true;
    }
    event.preventDefault();
    event.stopPropagation();
    this.dateFilterOpen = !this.dateFilterOpen;
  }

  /**
   * Apply the date filter and close the dialog
   */
  public applyDateFilter() {
    this.dateFilterOpen = false;
    this.search(this.searchBox.value);
  }

  /**
   * Clear the date filter
   */
  public clearDateFilter() {
    this.filterStartDate.setValue(null);
    this.filterEndDate.setValue(null);
    this.search(this.searchBox.value);
    this.dateFilterOpen = false;
  }

  @HostListener('document:click', ['$event.target'])
  public onClickOutside(target: HTMLElement) {
    // First check if clicking on datepicker elements
    const isDatepickerClick = target.closest('.mat-datepicker-content');
    if (isDatepickerClick) {
      return; // Don't close anything if clicking in datepicker
    }

    // Then check other elements
    const clickedInside = target.closest('.relative');
    if (!clickedInside) {
      this.closeDropdown();
      this.dateFilterOpen = false;
    }
  }

  public handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeDropdown();
      this.dateFilterOpen = false;
    }
  }

  public search(text: string) {
    this.searchText$$.next(text);
  }

  private formatDateForQuery(date: Date): string | null {
    if (!date) return null;
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }

  private listenToSearchTextChange() {
    const subscription = this.searchText$$
      .pipe(debounceTime(50))
      .pipe(
        switchMap((searchText) => {
          this.loading$$.next(true);
          if (searchText) {
            const filterStart = this.formatDateForQuery(this.filterStartDate.value);
            const filterEnd = this.formatDateForQuery(this.filterEndDate.value);
            // Get the user's timezone
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const params: { [key: string]: string } = {
              q: searchText,
              timezone: userTimezone, // Add timezone to request params
            };

            if (filterStart) {
              params['filterStart'] = filterStart;
            }

            if (filterEnd) {
              params['filterEnd'] = filterEnd;
            }

            return this.http
              .get<{ results: VoogleSearchResult[] }>(
                `${environment.api}/v1/external/broker_portal/vst/voogle/search`,
                { params },
              )
              .pipe(catchError(() => of({ results: [] })));
          } else {
            return of({ results: [] });
          }
        }),
      )
      .subscribe((results) => {
        this.loading$$.next(false);
        this.searchResults$$.next(results?.results || []);
      });

    this.subscriptions.push(subscription);
  }

  public getRouterLink(res: VoogleSearchResult): string[] {
    switch (res.type) {
      case 'Shipment':
        return ['/vst/loads', res.id];
      case 'Trailer':
        return ['/vst/asset_dashboard/trailer', res.id];
      case 'Facility':
        return ['/vst/facility', res.id];
      default:
        return ['/'];
    }
  }

  protected readonly document = document;
  protected readonly loadStatuses = LohiLoadStatus;
}
