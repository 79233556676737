<csvbox-button
  [licenseKey]="licenseKey"
  [onImport]="onData.bind(this)"
  [lazy]="true"
  [user]="user$ | async"
  [environment]="env"
  [dynamicColumns]="dynamicColumns"
>
  <ng-content></ng-content>
</csvbox-button>
