<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="over"
    class="w-full lg:w-1/2 xl:w-1/3 bg-white dark:bg-zinc-900 max-h-screen"
    [autoFocus]="false"
  >
    <tb-side-nav (click)="sidenav.close()"></tb-side-nav>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-screen">
      <div
        class="h-6 flex relative items-center bg-white dark:bg-gray-900 z-10 shadow"
        style="min-height: 48px"
        *ngIf="isLoggedIn$ | async"
      >
        <div class="w-8 flex items-center justify-center">
          <mat-icon class="cursor-pointer" (click)="sidenav.open()">menu</mat-icon>
        </div>
        <div class="flex-1 flex items-center justify-center">
          <div *tbFeatureFlag="'allow-voogle-search-tb'"><tb-voogle></tb-voogle></div>
          <!--<a [routerLink]="['/']" class="mx-auto pr-5">
            <img src="assets/logos/vorto-white.svg" class="hidden dark:block w-[100px]" alt="logo" />
            <img src="assets/logos/vorto.svg" class="dark:hidden w-[100px]" alt="logo" />
          </a>-->
          <tb-breadcrumb class="ml-1"></tb-breadcrumb>
        </div>
        <div *tbFeatureFlag="'requires-g3-branding'" class="justify-right mr-1">
          <img src="assets/logos/g3.svg" class="w-7 h-7" />
        </div>
      </div>
      <div class="flex-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
