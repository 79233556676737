import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

// eslint-disable-next-line
declare const Intercom: any;

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private matSnackBar: MatSnackBar) {}

  public showMessage(message: string, config?: MatSnackBarConfig): void {
    const defaultConfig: MatSnackBarConfig = {
      duration: 2000,
      ...config,
    };
    this.matSnackBar.open(message, defaultConfig.duration === 0 ? 'Dismiss' : null, defaultConfig);
  }

  public showError(message: string, config?: MatSnackBarConfig): void {
    const defaultConfig: MatSnackBarConfig = {
      duration: 2000,
      panelClass: ['snackbar-error'],
      ...config,
    };
    this.matSnackBar.open(message, defaultConfig.duration === 0 ? 'Dismiss' : null, defaultConfig);
  }
}
