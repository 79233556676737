import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService, CustomerType } from '../services/auth.service';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, combineLatestWith, Observable, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[tbInternal]',
})
export class InternalDirective implements OnDestroy {
  private hasView = false;
  private destroy$$ = new Subject<void>();

  private isInternal$: Observable<boolean>;
  private elseTemplateRef$$ = new BehaviorSubject<TemplateRef<any>>(null);

  // if not internal, show this template
  @Input()
  public set tbInternal(_: void) {}

  @Input()
  public set tbInternalElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef$$.next(templateRef);
  }

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.isInternal$ = this.authService.userInfo$.pipe(
      filter((v) => !!v),
      map((v) => v.internal),
    );
    this.listenForViewUpdates();
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private listenForViewUpdates() {
    this.isInternal$
      .pipe(takeUntil(this.destroy$$), combineLatestWith(this.elseTemplateRef$$))
      .subscribe(([isInternal, elseRef]) => {
        this.clearView();
        if (!isInternal) {
          this.renderElseView(elseRef);
        } else {
          this.renderView();
        }
      });
  }

  private renderView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      this.cd.markForCheck();
    }
  }

  private renderElseView(elseRef: TemplateRef<any>) {
    if (elseRef) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(elseRef);
        this.hasView = true;
        this.cd.markForCheck();
      }
    } else {
      this.clearView();
    }
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      this.cd.markForCheck();
    }
  }
}
