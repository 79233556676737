import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MaintenanceTicket, MaintenanceTicketDuration } from '../../services/maintenance.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'tb-maintenance-tickets-display',
  templateUrl: './maintenance-tickets-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTicketsDisplayComponent {
  @Input() public maintenanceTickets$!: Observable<MaintenanceTicket[]>;
  @Input() public maintenanceTicketDurations$!: Observable<Record<string, MaintenanceTicketDuration>>;

  public maintenanceWebUrl = environment.maintenanceWeb;

  constructor() {}

  public getTimeMessage(duration: MaintenanceTicketDuration): string {
    const now = new Date();
    const expectedEndTime = new Date(duration.expectedEndTime);
    const startTime = new Date(duration.startTime);
    const expectedDurationMs = parseInt(duration.expectedDuration) * 1000;

    const timeDifferenceMs = expectedEndTime.getTime() - now.getTime();
    const isExpectedEndTimeInFuture = timeDifferenceMs > 0;

    const startTimePlusDuration = new Date(startTime.getTime() + expectedDurationMs);
    const startTimePlusDurationDifferenceMs = startTimePlusDuration.getTime() - now.getTime();
    const isStartTimePlusDurationInFuture = startTimePlusDurationDifferenceMs > 0;

    const formatDuration = (ms: number): string => {
      const totalSeconds = Math.floor(ms / 1000);
      const days = Math.floor(totalSeconds / (60 * 60 * 24));
      const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);

      let result = '';
      if (days > 0) {
        result += days === 1 ? '1 day ' : `${days} day(s) `;
      }
      if (hours > 0) {
        result += hours === 1 ? '1 hour ' : `${hours} hour(s) `;
      }
      if (minutes > 0 && days === 0) {
        result += minutes === 1 ? '1 minute ' : `${minutes} minute(s) `;
      }
      return result.trim();
    };

    if (isExpectedEndTimeInFuture) {
      const preStr = duration.isVendorProvided ? 'Vendor Provided ETA to Repair:' : 'ETA to Repair:';
      return `${preStr} ${formatDuration(timeDifferenceMs)}`;
    } else if (isStartTimePlusDurationInFuture) {
      return `ETA to Repair: ${formatDuration(startTimePlusDurationDifferenceMs)}`;
    } else {
      const elapsedTimeMs = now.getTime() - startTime.getTime();
      return `Usually takes ${formatDuration(expectedDurationMs)} (it has been ${formatDuration(elapsedTimeMs)})`;
    }
  }
}
