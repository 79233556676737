<ng-container *ngIf="maintenanceTicketDurations$ | async as durations">
  <ng-container
    *ngFor="
      let ticket of maintenanceTickets$ | async;
      let last = last;
      trackBy: maintenanceTickets$ | trackByKey : 'id'
    "
  >
    <div>
      <a [href]="maintenanceWebUrl + '/tickets/' + ticket.pid" target="_blank" class="text-blue-500">
        {{ ticket.pid }}
      </a>
      <span *ngIf="durations[ticket.id]">
        - Trailer Component: {{ durations[ticket.id].componentName }}. Issue: {{ durations[ticket.id].issueName }}.
        {{ getTimeMessage(durations[ticket.id]) }}
      </span>
    </div>
  </ng-container>
</ng-container>
