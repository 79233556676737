import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MapPipe } from './pipes/map.pipe';
import { TrackByKeyPipe } from './pipes/track-by-key.pipe';
import { StandardDatePipe } from './pipes/standard-date.pipe';
import { StandardTimePipe } from './pipes/standard-time.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { NumberBadgeComponent } from './components/number-badge/number-badge.component';
import { CurrencyInPenniesPipe } from './pipes/currency-in-pennies.pipe';
import { DashIfNothingPipe } from './pipes/dash-if-nothing.pipe';
import { IsPhonePipe } from './pipes/is-phone.pipe';
import { PageTitleDirective } from './directives/page-title.directive';
import { HighlightTextDirective } from './directives/highlight-text.directive';
import { RouterModule } from '@angular/router';
import { IsImagePipe } from './pipes/is-image.pipe';
import { CompareWithPipe } from './pipes/compare-with.pipe';
import { CurrencyInputDirective } from './directives/money.directive';
import { NumberInputDirective } from './directives/number-input.directive';
import { RecordCallDirective } from './directives/record-call.directive';
import { MinutesToHumanPipe } from './pipes/minutes-to-human.pipe';
import { AgoPipe } from './pipes/ago.pipe';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { AddMinutesPipe } from './pipes/add-minutes.pipe';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountTypeDirective } from './directives/account-type.directive';
import { StopMapComponent } from './components/stop-map/stop-map.component';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { InternalDirective } from './directives/internal.directive';
import { SumPipe } from './pipes/sum.pipe';
import { InHoursPipe } from './pipes/in-hours.pipe';
import { IsBeforePipe } from './pipes/is-before.pipe';
import { LohiLoadStatusPipe } from './pipes/lohi-load-status.pipe';
import { DatePickerDirective } from './directives/date-picker.directive';
import { JoinWithAndPipe } from './pipes/join-with-and.pipe';
import { TrailerTypePipe } from './pipes/trailer-type.pipe';
import { SortIndicatorComponent } from './components/sort-indicator/sort-indicator.component';
import { IsAfterPipe } from './pipes/is-after.pipe';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { InTimezonePipe } from './pipes/in-timezone.pipe';
import { TimezoneNamePipe } from './pipes/timezone-name.pipe';
import { StopTypePipe } from './pipes/stop-type.pipe';
import { TextToDateComponent } from './components/text-to-date/text-to-date.component';
import { AssetPipe } from './pipes/asset-type.pipe';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { CheckInventoryThresholdPipe } from './pipes/check-inventory-theshold.pipe';
import { FromNowPipe } from './pipes/from-now.pipe';
import { IsPastPipe } from './pipes/is-past.pipe';
import { IsFuturePipe } from './pipes/is-future.pipe';
import { DoubleConfirmComponent } from './components/double-confirm/double-confirm.component';
import { FadeComponent } from './components/fade/fade.component';
import { LoadTypePipe } from './pipes/load-type.pipe';
import { AddressSearchV2Component } from './components/address-search-v2/address-search-v2.component';
import { ShipmentTypePipe } from './pipes/shipment-type.pipe';
import { ShipmentStatusUpdatePipe } from './pipes/shipment-status-update.pipe';
import { SelectCustomerComponent } from './components/select-customer/select-customer.component';
import { TimeTickerComponent } from './components/time-ticker/time-ticker.component';
import { RFC3339DateOnlyPipe } from './pipes/rfc3339-date-only';
import { StandardDateOnlyPipe } from './pipes/standard-date-only.pipe';
import { BreadcrumbDirective } from './directives/breadcrumb.directive';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AgGridDateCountdownComponent } from './components/ag-grid-date-countdown/ag-grid-date-countdown.component';
import { DarkModeClassDirective } from './directives/dark-mode-class.directive';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { TrailerStatusPipe } from './pipes/trailer-status.pipe';
import { PlusMorePipe } from './pipes/plus-more.pipe';
import { DifferenceInMinutesPipe } from './pipes/difference-in-minutes.pipe';
import { FeaturePermissionDirective } from './directives/feature-permission.directive';
import { MaterialModule } from './material/material.module';
import { ShipmentExportComponent } from './components/shipment-export/shipment-export.component';
import { FusedSearchElementComponent } from './components/fused-search-element/fused-search-element.component';
import { MinutesToHoursAndMinutesPipe } from './pipes/minutes-to-hours-and-minutes.pipe';
import { EnumPipe } from './pipes/enum.pipe';
import { RemoveAriaOwnsDirective } from './directives/remove-aria-owns.directive';
import { ZeroIfNothingPipe } from './pipes/zero-if-nothing';
import { InternalOrExternalDispatcherDirective } from './directives/internalOrExternal.directive';
import { NoSafeHtmlPipe } from './pipes/no-safe-html.pipe';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { PolygonalGeofenceFacilityComponent } from './components/polygonal-geofence-facility/polygonal-geofence-facility.component';
import { VoogleSearchComponent } from './voogle/voogle-search.component';
import { CsvImporterButtonComponent } from './components/csv-importer-button/csv-importer-button.component';
import { CSVBoxAngularModule } from '@csvbox/angular';
import { MaintenanceTicketsDisplayComponent } from './components/maintenance-tickets-display/maintenance-tickets-display.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

/* eslint-disable @typescript-eslint/naming-convention */
const tooltipOptions = {
  placement: 'top',
  'show-delay': 200,
  'tooltip-class': 'tooltip-overrides',
};

/* eslint-enable @typescript-eslint/naming-convention */

@NgModule({
  declarations: [
    FeatureFlagDirective,
    TrackByKeyPipe,
    SideNavComponent,
    MapPipe,
    StandardDatePipe,
    StandardTimePipe,
    PhonePipe,
    NumberBadgeComponent,
    CurrencyInPenniesPipe,
    DashIfNothingPipe,
    IsPhonePipe,
    PageTitleDirective,
    HighlightTextDirective,
    IsImagePipe,
    CompareWithPipe,
    CurrencyInputDirective,
    NumberInputDirective,
    RecordCallDirective,
    MinutesToHumanPipe,
    AgoPipe,
    AddMinutesPipe,
    AddressSearchComponent,
    AccountTypeDirective,
    StopMapComponent,
    CreateCustomerComponent,
    InternalDirective,
    InternalOrExternalDispatcherDirective,
    SumPipe,
    InHoursPipe,
    IsBeforePipe,
    IsAfterPipe,
    LohiLoadStatusPipe,
    DatePickerDirective,
    JoinWithAndPipe,
    TrailerTypePipe,
    SortIndicatorComponent,
    InTimezonePipe,
    TimezoneNamePipe,
    StopTypePipe,
    TextToDateComponent,
    AssetPipe,
    LightboxComponent,
    PhotoGalleryComponent,
    CheckInventoryThresholdPipe,
    FromNowPipe,
    IsPastPipe,
    IsFuturePipe,
    DoubleConfirmComponent,
    FadeComponent,
    LoadTypePipe,
    AddressSearchV2Component,
    ShipmentTypePipe,
    ShipmentStatusUpdatePipe,
    SelectCustomerComponent,
    TimeTickerComponent,
    RFC3339DateOnlyPipe,
    StandardDateOnlyPipe,
    BreadcrumbDirective,
    BreadcrumbComponent,
    AgGridDateCountdownComponent,
    DarkModeClassDirective,
    OrdinalPipe,
    TrailerStatusPipe,
    PlusMorePipe,
    DifferenceInMinutesPipe,
    FeaturePermissionDirective,
    ShipmentExportComponent,
    FusedSearchElementComponent,
    MinutesToHoursAndMinutesPipe,
    EnumPipe,
    RemoveAriaOwnsDirective,
    ZeroIfNothingPipe,
    NoSafeHtmlPipe,
    PolygonalGeofenceFacilityComponent,
    VoogleSearchComponent,
    CsvImporterButtonComponent,
    MaintenanceTicketsDisplayComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TooltipModule.forRoot(tooltipOptions),
    RouterModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    ReactiveFormsModule,
    CSVBoxAngularModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [
    MaterialModule,
    TooltipModule,
    TrackByKeyPipe,
    SideNavComponent,
    MapPipe,
    StandardDatePipe,
    StandardTimePipe,
    PhonePipe,
    NumberBadgeComponent,
    CurrencyInPenniesPipe,
    DashIfNothingPipe,
    IsPhonePipe,
    PageTitleDirective,
    HighlightTextDirective,
    IsImagePipe,
    CompareWithPipe,
    CurrencyInputDirective,
    NumberInputDirective,
    RecordCallDirective,
    MinutesToHumanPipe,
    AgoPipe,
    AddMinutesPipe,
    AddressSearchComponent,
    AccountTypeDirective,
    StopMapComponent,
    CreateCustomerComponent,
    InternalDirective,
    InternalOrExternalDispatcherDirective,
    SumPipe,
    InHoursPipe,
    IsBeforePipe,
    IsAfterPipe,
    LohiLoadStatusPipe,
    DatePickerDirective,
    JoinWithAndPipe,
    TrailerTypePipe,
    SortIndicatorComponent,
    FeatureFlagDirective,
    InTimezonePipe,
    TimezoneNamePipe,
    StopTypePipe,
    TextToDateComponent,
    AssetPipe,
    LightboxComponent,
    PhotoGalleryComponent,
    CheckInventoryThresholdPipe,
    FromNowPipe,
    IsPastPipe,
    IsFuturePipe,
    DoubleConfirmComponent,
    FadeComponent,
    LoadTypePipe,
    AddressSearchV2Component,
    ShipmentTypePipe,
    ShipmentStatusUpdatePipe,
    SelectCustomerComponent,
    TimeTickerComponent,
    RFC3339DateOnlyPipe,
    StandardDateOnlyPipe,
    BreadcrumbDirective,
    BreadcrumbComponent,
    AgGridDateCountdownComponent,
    DarkModeClassDirective,
    OrdinalPipe,
    TrailerStatusPipe,
    PlusMorePipe,
    DifferenceInMinutesPipe,
    FeaturePermissionDirective,
    FusedSearchElementComponent,
    MinutesToHoursAndMinutesPipe,
    EnumPipe,
    RemoveAriaOwnsDirective,
    ZeroIfNothingPipe,
    NoSafeHtmlPipe,
    PolygonalGeofenceFacilityComponent,
    VoogleSearchComponent,
    CsvImporterButtonComponent,
    MaintenanceTicketsDisplayComponent,
    ConfirmDialogComponent,
  ],
  providers: [
    TrailerTypePipe,
    StopTypePipe,
    InTimezonePipe,
    TrailerStatusPipe,
    StandardDatePipe,
    LohiLoadStatusPipe,
    TitleCasePipe,
    EnumPipe,
  ],
})
export class SharedModule {}
